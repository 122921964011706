import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isShowContactUs: false
}

export const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    setIsShowContactUs: (state, action) => {
      state.isShowContactUs = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setIsShowContactUs } = commonSlice.actions

export default commonSlice.reducer
// HeadsetIcon.js
import React from 'react';

const HeadsetIcon = () => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="48" height="48" rx="24" fill="#1D54B5" fill-opacity="0.1" />
        <path d="M33 20.5H15M28 14.5V17.5M20 14.5V17.5M24 30.5V24.5M21 27.5H27M19.8 34.5H28.2C29.8802 34.5 30.7202 34.5 31.362 34.173C31.9265 33.8854 32.3854 33.4265 32.673 32.862C33 32.2202 33 31.3802 33 29.7V21.3C33 19.6198 33 18.7798 32.673 18.138C32.3854 17.5735 31.9265 17.1146 31.362 16.827C30.7202 16.5 29.8802 16.5 28.2 16.5H19.8C18.1198 16.5 17.2798 16.5 16.638 16.827C16.0735 17.1146 15.6146 17.5735 15.327 18.138C15 18.7798 15 19.6198 15 21.3V29.7C15 31.3802 15 32.2202 15.327 32.862C15.6146 33.4265 16.0735 33.8854 16.638 34.173C17.2798 34.5 18.1198 34.5 19.8 34.5Z" stroke="#1D54B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>



);

export default HeadsetIcon;
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './HomePageStyles.css';
import { Button, Image, Modal } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setServicesTabsData } from '../../app/slices/homeContentSlice';
import DynamicSection from '../../components/DynamicSection/DynamicSection';
import PricingCard from '../../components/PricingCard/PricingCard';
import YouTube from 'react-youtube';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'; // Import statement added
import selfStorageImage from '../../assets/images/selfStorage.png';
import rvStorageImage from '../../assets/images/rvResort.png';
import communityImage from '../../assets/images/community.png';
import golfCourseImage from '../../assets/images/golfCourse.png';
import { Element } from 'react-scroll';

import HeadsetIcon from '../../icons/headset';
import LinkIcon from '../../icons/LinkIcon';
import TimerIcon from '../../icons/TimerIcon';
import ProcessIcon from '../../icons/ProcessIcon';
import KeyIcon from '../../icons/KeyIcon';
import TextIcon from '../../icons/TextIcon';
import Callicon from '../../icons/CallIcon';
import LightiningIcon from '../../icons/LightingIcon';
import CalenderIcon from '../../icons/CalenderIcon';
import PopularIcon from '../../icons/PopularIcon';

const HomePage = () => {
    const { heading, subHeading, button } = useSelector(state => state.homeContent.bannerSection);
    const { servicesTabsData } = useSelector(state => state.homeContent.servicesSection);
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [videoId, setVideoId] = useState("");

    const handleShowModal = (videoId) => {
        setVideoId(videoId);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setVideoId("");
    };

    const data = [
        {
            heading: "Self Storage/RV & Boat Storage",
            points: [
                { icon: HeadsetIcon, text: 'Customer Service' },
                { icon: LinkIcon, text: 'Outbound Collections' },
                { icon: TimerIcon, text: 'Set sales appointment' },
                { icon: ProcessIcon, text: 'Process ancillary sales' },
                { icon: KeyIcon, text: 'Process leases' },
                { icon: TextIcon, text: 'Collect inbound lead data with follow up sales mechanism' },
                { icon: Callicon, text: 'Maintenance/management calls' },
            ],
            button: { text: "Watch Demo", link: "Xwftv_r75NY" }, // YouTube video ID
            ButtonIcon: PlayCircleOutlineOutlinedIcon,
            onClickCTA: handleShowModal,
            image: selfStorageImage,
            layout: 'right',
            backgroundColor: 'white'
        },
        {
            heading: "RV Resort/Hospitality",
            points: [
                { icon: HeadsetIcon, text: 'Customer Service' },
                { icon: LightiningIcon, text: 'Take Reservations' },
                { icon: ProcessIcon, text: 'Process ancillary sales' },
                { icon: TextIcon, text: 'Collect inbound lead data with follow up sales mechanism' },
                { icon: TimerIcon, text: 'Set sales appointment' },
                { icon: Callicon, text: 'Maintenance/management calls' },
            ],
            button: { text: "Watch Demo", link: "-XUIjok5N0A" }, // YouTube video ID
            ButtonIcon: PlayCircleOutlineOutlinedIcon,
            onClickCTA: handleShowModal,
            image: rvStorageImage,
            layout: 'left',
            backgroundColor: 'grey'
        },
        {
            heading: "Manufactured Housing Community/Apartments",
            points: [
                { icon: HeadsetIcon, text: 'Customer Service' },
                { icon: LinkIcon, text: 'Outbound Collections' },
                { icon: ProcessIcon, text: 'Process ancillary sales' },
                { icon: TextIcon, text: 'Collect inbound lead data with follow up sales mechanism' },
                { icon: KeyIcon, text: 'Process leases' },
                { icon: TimerIcon, text: 'Set sales appointment' },
                { icon: Callicon, text: 'Maintenance/management calls' },
            ],
            button: { text: "Watch Demo", link: "8T9F2vtahLA" }, // YouTube video ID
            ButtonIcon: PlayCircleOutlineOutlinedIcon,
            onClickCTA: handleShowModal,
            image: communityImage,
            layout: 'right',
            backgroundColor: 'white'
        },
        {
            heading: "Golf Course",
            points: [
                { icon: HeadsetIcon, text: 'Customer Service' },
                { icon: ProcessIcon, text: 'Process ancillary sales' },
                { icon: TimerIcon, text: 'Set sales appointment' },
                { icon: TextIcon, text: 'Collect inbound lead data with follow up sales mechanism' },
                { icon: CalenderIcon, text: 'Reserve tee times' },
                { icon: Callicon, text: 'Maintenance/management calls' },
            ],
            button: { text: "Watch Demo", link: "7hPr6Lq82u4" }, // YouTube video ID
            ButtonIcon: PlayCircleOutlineOutlinedIcon,
            onClickCTA: handleShowModal,
            image: golfCourseImage,
            layout: 'left',
            backgroundColor: 'grey'
        }
    ];

    const pricingData = [
        {
            title: "iRES",
            features: [
                "Customer service",
                "Outbound collections",
                "Process sales transactions",
                "Set sales appointment",
                "Maintenance/management calls",
                "Collect inbound lead data with follow up sales mechanism",
                "Call feature"
            ],
            buttonText: "Contact sales",
            highlighted: false
        },
        {
            title: "iRES +",
            features: [
                "Customer service",
                "Outbound collections",
                "Process sales transactions",
                "Set sales appointment",
                "Maintenance/management calls",
                "Collect inbound lead data with follow up sales mechanism",
                "Call feature",
                "Messaging with website chatbot"
            ],
            buttonText: "Contact sales",
            highlighted: true
        },
        {
            title: "iRES premium",
            features: [
                "Customer service",
                "Outbound collections",
                "Process sales transactions",
                "Set sales appointment",
                "Maintenance/management calls",
                "Collect inbound lead data with follow up sales mechanism",
                "Call feature",
                "Messaging with website chatbot",
                "Website",
                "Property management system"
            ],
            buttonText: "Contact sales",
            highlighted: false
        }
    ];

    return (
        <Container fluid className="homePageContainer">
            <Row className="banner">
                <div className="headerContainer">
                    <h1 className="heading">{heading}</h1>
                    <p className="subHeading">{subHeading}</p>
                    <Button className='requestDemoBtn' onClick={() => handleShowModal("Xwftv_r75NY")}>
                        <span className='requestDemoBtnText'>{button?.buttonName}</span>
                        <PlayCircleOutlineOutlinedIcon />
                    </Button>
                </div>
            </Row>
            <Row>
                <h1 className='sectionHeader'>Specialized AI business solutions</h1>
            </Row>
            {data.map((section, index) => (
                <DynamicSection
                    key={index}
                    heading={section.heading}
                    points={section.points}
                    button={section.button}
                    ButtonIcon={section.ButtonIcon}
                    onClickCTA={() => handleShowModal(section.button.link)}
                    image={section.image}
                    layout={section.layout}
                    backgroundColor={section.backgroundColor}
                />
            ))}
            <Row className='sectionHeader'>
                <h1 style={{ fontWeight: 'bolder' }}>Choose Your Plan</h1>
                <p style={{ textAlign: 'center', fontWeight: '100', marginTop: '10px' }}>Pick from three plans to get the features and benefits you need</p>
                <div className='popularIcon'>
                    <PopularIcon />
                </div>
            </Row>
            <Row>
                <Element name="productsSection" className="productsSection pricing-section-container">
                    {pricingData.map((card, index) => (
                        <PricingCard
                            key={index}
                            title={card.title}
                            features={card.features}
                            buttonText={card.buttonText}
                            highlighted={card.highlighted}
                        />
                    ))}
                </Element>
            </Row>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Watch Demo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <YouTube videoId={videoId} opts={{ width: '100%', height: '390', playerVars: { autoplay: 1, rel: 0 } }} />
                </Modal.Body>
            </Modal>
            <Outlet />
        </Container>
    );
}

export default HomePage;

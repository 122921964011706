// HeadsetIcon.js
import React from 'react';

const HeadsetIcon = () => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 24.5C0 11.2452 10.7452 0.5 24 0.5C37.2548 0.5 48 11.2452 48 24.5C48 37.7548 37.2548 48.5 24 48.5C10.7452 48.5 0 37.7548 0 24.5Z" fill="#1D54B5" fill-opacity="0.1" />
        <path d="M26 14.7695V18.9001C26 19.4601 26 19.7401 26.109 19.9541C26.2049 20.1422 26.3578 20.2952 26.546 20.3911C26.7599 20.5001 27.0399 20.5001 27.6 20.5001H31.7305M26 29.5H20M28 25.5H20M32 22.4882V29.7C32 31.3802 32 32.2202 31.673 32.862C31.3854 33.4265 30.9265 33.8854 30.362 34.173C29.7202 34.5 28.8802 34.5 27.2 34.5H20.8C19.1198 34.5 18.2798 34.5 17.638 34.173C17.0735 33.8854 16.6146 33.4265 16.327 32.862C16 32.2202 16 31.3802 16 29.7V19.3C16 17.6198 16 16.7798 16.327 16.138C16.6146 15.5735 17.0735 15.1146 17.638 14.827C18.2798 14.5 19.1198 14.5 20.8 14.5H24.0118C24.7455 14.5 25.1124 14.5 25.4577 14.5829C25.7638 14.6564 26.0564 14.7776 26.3249 14.9421C26.6276 15.1276 26.887 15.387 27.4059 15.9059L30.5941 19.0941C31.113 19.613 31.3724 19.8724 31.5579 20.1751C31.7224 20.4436 31.8436 20.7362 31.9171 21.0423C32 21.3876 32 21.7545 32 22.4882Z" stroke="#1D54B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

);

export default HeadsetIcon;
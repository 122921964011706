import React, { useEffect, useState } from "react";
import { Container, Row, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import './EditHome.css';
import { setBannerSection } from "../../app/slices/homeContentSlice";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebaseConfig";
import { doc, setDoc, getDoc } from "firebase/firestore";

const EditHome = () => {
    const bannerSection = useSelector(state=>state.homeContent.bannerSection);
    const [bannerSectionValue, setBannerSectionValue] = useState({
        heading: bannerSection.heading,
        subHeading: bannerSection.subHeading,
        button: bannerSection.button
    })
    const [selfStorage, setSelfStorage] = useState({ heading: "", description: "" });
    const [rvResort, setRvResort] = useState({ heading: "", description: "" });
    const [manufacturedHousing, setManufacturedHousing] = useState({ heading: "", description: "" });

    const navigate = useNavigate();
    console.log(bannerSection);
    const dispatch = useDispatch();

    useEffect(() => {
        // Load the initial data from Firestore
        const fetchBannerSection = async () => {
            const docRef = doc(db, "bannerSection", "current");
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setBannerSectionValue(docSnap.data());
            } else {
                console.log("No such document!");
            }
        };
        fetchBannerSection();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const loadSection = async (docId, setter) => {
                const docRef = doc(db, "bannerSections", docId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    setter(docSnap.data());
                } else {
                    console.log(`No data found for ${docId}`);
                }
            };
    
            loadSection("selfStorage", setSelfStorage);
            loadSection("rvResort", setRvResort);
            loadSection("manufacturedHousing", setManufacturedHousing);
        };
    
        fetchData();
    }, []);
    

    const onChange = (fieldName, value) => {
        console.log(fieldName, value);
        const bannerSectionObject = {...bannerSectionValue};
        let button = bannerSectionObject?.button;
        console.log(button, fieldName==="button");
        if(fieldName==="button"){
            button = {...button, buttonName: value};
            bannerSectionObject['button'] = button;
        }else{
            bannerSectionObject[fieldName] = value;
        }
        console.log(bannerSectionObject);
        setBannerSectionValue(bannerSectionObject);
    }

    const onChangeData = (setter, fieldName, value) => {
        setter(prev => ({
            ...prev,
            [fieldName]: value
        }));
    };
    

    /**
     * on click of "Save" button, it will save the data to redux store
     */
    const saveData = async () => {
        console.log(bannerSection);
        dispatch(setBannerSection(bannerSectionValue));
        const docRef = doc(db, "bannerSection", "current"); // use a specific document with id "current"
        await setDoc(docRef, bannerSectionValue, { merge: true })
            .then(() => {
                console.log("Document successfully updated!");
                navigate('/');
            })
            .catch(error => {
                console.error("Error updating document: ", error);
            });
    }

    const saveSection = async (sectionData, sectionId) => {
        const docRef = doc(db, "bannerSections", sectionId);
        await setDoc(docRef, sectionData, { merge: true })
            .then(() => {
                console.log(`${sectionId} successfully updated!`);
                navigate('/');  // or handle navigation based on specific logic
            })
            .catch(error => {
                console.error(`Error updating ${sectionId}: `, error);
            });
    };
    

    return (
    <Container fluid className="editHomeContainer">
    <div>
        <h1>Banner Section</h1>
        <Row className="sectionRow headingRow">
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label bsPrefix="headingLabel">Heading:</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Type your heading here" 
                        value={bannerSectionValue.heading}
                        onChange={(event)=>onChange("heading", event.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label bsPrefix="headingLabel">Sub Heading:</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Type your sub heading here" 
                        value={bannerSectionValue.subHeading}
                        onChange={(event)=>onChange("subHeading", event.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label bsPrefix="headingLabel">Sub Heading:</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Type button name" 
                        value={bannerSectionValue.button?.buttonName}
                        onChange={(event)=>onChange("button", event.target.value)}
                    />
                </Form.Group>
                <Button variant="primary" onClick={()=>saveData()}>Save</Button>
                
            </Form>
        </Row>
        </div>

        <div>
        <h1>Help us Section</h1>
        <h3>Self Storage</h3>
        <Row className="sectionRow headingRow">
        <Form>
        <Form.Group className="mb-3" controlId="selfStorageHeading">
            <Form.Label bsPrefix="headingLabel">Heading:</Form.Label>
            <Form.Control 
                type="text" 
                placeholder="Type your heading here" 
                value={selfStorage.heading}
                onChange={(event) => onChangeData(setSelfStorage, "heading", event.target.value)}
            />
            
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label bsPrefix="headingLabel">Sub Heading:</Form.Label>
                    <Form.Control 
                        as="textarea"
                        placeholder="Type your sub heading here"
                        value={selfStorage.subHeading}
                        onChange={(event) => onChangeData(setSelfStorage,"subHeading", event.target.value)}
                        rows={3}  // You can specify the number of rows to control the height of the textarea
                    />

                </Form.Group>
        <Button variant="primary" onClick={() => saveSection(selfStorage, "selfStorage")}>Save</Button>
        </Form>
        </Row>
        <h3>RV Resort</h3>
        <Row className="sectionRow headingRow">
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label bsPrefix="headingLabel">Heading:</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Type your heading here" 
                        value={bannerSectionValue.heading}
                        onChange={(event)=>onChange("heading", event.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label bsPrefix="headingLabel">Sub Heading:</Form.Label>
                    <Form.Control 
                        as="textarea"
                        placeholder="Type your sub heading here"
                        value={bannerSectionValue.subHeading}
                        onChange={(event) => onChangeData("subHeading", event.target.value)}
                        rows={3}  // You can specify the number of rows to control the height of the textarea
                    />

                </Form.Group>
               
                {/* <Button variant="primary" onClick={()=>saveData()}>Save</Button> */}
                <Button variant="primary" onClick={() => saveSection(selfStorage, "selfStorage")}>Save</Button>
            </Form>
        </Row>
        <h3>Manufactured Housing</h3>
        <Row className="sectionRow headingRow">
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label bsPrefix="headingLabel">Heading:</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Type your heading here" 
                        value={bannerSectionValue.heading}
                        onChange={(event)=>onChange("heading", event.target.value)}
                    />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label bsPrefix="headingLabel">Sub Heading:</Form.Label>
                    <Form.Control 
                        as="textarea"
                        placeholder="Type your sub heading here"
                        value={bannerSectionValue.subHeading}
                        onChange={(event) => onChange("subHeading", event.target.value)}
                        rows={3}  // You can specify the number of rows to control the height of the textarea
                    />

                </Form.Group>
                
                {/* <Button variant="primary" onClick={()=>saveData()}>Save</Button> */}
                <Button variant="primary" onClick={() => saveSection(selfStorage, "selfStorage")}>Save</Button>
            </Form>
        </Row>
        </div>
    </Container>)
}   

export default EditHome
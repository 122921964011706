export const headerPageContent = {
    banner: {
        heading: "Commercial Real Estate's AI Contact Center",
        subHeading: "The future is now with iRES",
        buttonName: 'SEE IT IN ACTION'
    },
    
}

export const servicesTabs = [{
    id: 1,
    name: 'Self Storage',
    isActive: true,
    content: [
        "Customer Service",
        "Take Reservations",
        "Inventory/availability information",
        "Outbound Collections",
        "Collect inbound lead data with follow up sales mechanism"
    ]
},{
    id: 2,
    name: 'RV Resort',
    isActive: false,
    content: [
        "Customer Service",
        "Take Reservations",
        "Outbound Collections",
        "Inventory/availability information",
        "Collect inbound lead data with follow up sales mechanism",
        "Process ancillary sales",
        "Set sales appointment",
        "Maintenance/management"
    ]
},{
    id: 3,
    name: 'Manufactured Housing',
    isActive: false,
    content: [
        "Customer Service",
        "Take Reservations",
        "Inventory/availability information",
        "Set sales appointment",
        "Outbound Collections",
        "Collect inbound lead data with follow up sales mechanism",
        "Process ancillary sales",
        "Maintenance/management"
    ]
}]

export const youtubeLink = "https://www.youtube.com/@iresai";

export const contactUsModalData = {

}
// HeadsetIcon.js
import React from 'react';

const TimerIcon = () => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 24.5C0 11.2452 10.7452 0.5 24 0.5C37.2548 0.5 48 11.2452 48 24.5C48 37.7548 37.2548 48.5 24 48.5C10.7452 48.5 0 37.7548 0 24.5Z" fill="#1D54B5" fill-opacity="0.1" />
        <path d="M27 21.5H27.01M27 27.5C30.3137 27.5 33 24.8137 33 21.5C33 18.1863 30.3137 15.5 27 15.5C23.6863 15.5 21 18.1863 21 21.5C21 21.7737 21.0183 22.0431 21.0538 22.307C21.1122 22.7412 21.1414 22.9583 21.1217 23.0956C21.1013 23.2387 21.0752 23.3157 21.0047 23.4419C20.937 23.563 20.8177 23.6823 20.5791 23.9209L15.4686 29.0314C15.2957 29.2043 15.2092 29.2908 15.1474 29.3917C15.0925 29.4812 15.0521 29.5787 15.0276 29.6808C15 29.7959 15 29.9182 15 30.1627V31.9C15 32.4601 15 32.7401 15.109 32.954C15.2049 33.1422 15.3578 33.2951 15.546 33.391C15.7599 33.5 16.0399 33.5 16.6 33.5H18.3373C18.5818 33.5 18.7041 33.5 18.8192 33.4724C18.9213 33.4479 19.0188 33.4075 19.1083 33.3526C19.2092 33.2908 19.2957 33.2043 19.4686 33.0314L24.5791 27.9209C24.8177 27.6823 24.937 27.563 25.0581 27.4953C25.1843 27.4248 25.2613 27.3987 25.4044 27.3783C25.5417 27.3586 25.7588 27.3878 26.193 27.4462C26.4569 27.4817 26.7263 27.5 27 27.5Z" stroke="#1D54B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>



);

export default TimerIcon;
// HeadsetIcon.js
import React from 'react';

const HeadsetIcon = () => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 24.5C0 11.2452 10.7452 0.5 24 0.5C37.2548 0.5 48 11.2452 48 24.5C48 37.7548 37.2548 48.5 24 48.5C10.7452 48.5 0 37.7548 0 24.5Z" fill="#1D54B5" fill-opacity="0.1" />
        <path d="M25.0001 14.5L16.0936 25.1879C15.7448 25.6064 15.5703 25.8157 15.5677 25.9925C15.5654 26.1461 15.6338 26.2923 15.7534 26.3889C15.8909 26.5 16.1633 26.5 16.7081 26.5H24.0001L23.0001 34.5L31.9067 23.8121C32.2555 23.3936 32.4299 23.1843 32.4325 23.0075C32.4348 22.8539 32.3664 22.7077 32.2468 22.6111C32.1094 22.5 31.8369 22.5 31.2921 22.5H24.0001L25.0001 14.5Z" stroke="#1D54B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


);

export default HeadsetIcon;
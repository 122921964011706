// HeadsetIcon.js
import React from 'react';

const HeadsetIcon = () => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 24.5C0 11.2452 10.7452 0.5 24 0.5C37.2548 0.5 48 11.2452 48 24.5C48 37.7548 37.2548 48.5 24 48.5C10.7452 48.5 0 37.7548 0 24.5Z" fill="#1D54B5" fill-opacity="0.1" />
        <path d="M26.0497 18.5C27.0264 18.6906 27.924 19.1683 28.6277 19.8719C29.3314 20.5756 29.8091 21.4733 29.9997 22.45M26.0497 14.5C28.0789 14.7254 29.9713 15.6342 31.4159 17.077C32.8606 18.5198 33.7717 20.411 33.9997 22.44M22.2266 26.3631C21.0251 25.1615 20.0763 23.8028 19.3803 22.3532C19.3204 22.2285 19.2905 22.1662 19.2675 22.0873C19.1858 21.8069 19.2445 21.4627 19.4145 21.2253C19.4623 21.1584 19.5195 21.1013 19.6338 20.987C19.9834 20.6374 20.1582 20.4626 20.2725 20.2868C20.7035 19.6239 20.7035 18.7693 20.2725 18.1064C20.1582 17.9307 19.9834 17.7559 19.6338 17.4062L19.4389 17.2114C18.9075 16.6799 18.6417 16.4142 18.3564 16.2699C17.7888 15.9828 17.1185 15.9828 16.551 16.2699C16.2656 16.4142 15.9999 16.6799 15.4684 17.2114L15.3108 17.369C14.7812 17.8986 14.5164 18.1634 14.3141 18.5235C14.0897 18.923 13.9283 19.5435 13.9297 20.0017C13.9309 20.4146 14.011 20.6969 14.1712 21.2613C15.0322 24.2947 16.6567 27.1571 19.0447 29.545C21.4326 31.933 24.295 33.5575 27.3284 34.4185C27.8928 34.5787 28.1751 34.6588 28.588 34.66C29.0462 34.6614 29.6667 34.5 30.0662 34.2756C30.4263 34.0733 30.6911 33.8085 31.2207 33.2789L31.3783 33.1213C31.9098 32.5898 32.1755 32.3241 32.3198 32.0387C32.6069 31.4712 32.6069 30.8009 32.3198 30.2333C32.1755 29.948 31.9098 29.6822 31.3783 29.1508L31.1835 28.9559C30.8339 28.6063 30.6591 28.4315 30.4833 28.3172C29.8204 27.8862 28.9658 27.8862 28.3029 28.3172C28.1271 28.4315 27.9523 28.6063 27.6027 28.9559C27.4884 29.0702 27.4313 29.1274 27.3644 29.1752C27.127 29.3453 26.7828 29.404 26.5024 29.3222C26.4235 29.2992 26.3612 29.2693 26.2365 29.2094C24.7869 28.5134 23.4282 27.5646 22.2266 26.3631Z" stroke="#1D54B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


);

export default HeadsetIcon;
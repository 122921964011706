import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './FooterStyles.css';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsShowContactUs } from '../../app/slices/commonSlice';
import { Image } from 'react-bootstrap';
import IRES_LOGO from '../../assets/images/iRES_transparent.png';
import { ReactComponent as InstagramIcon } from '../../assets/images/instagram-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/images/youtube.svg';
import { ReactComponent as LinkedInIcon } from '../../assets/images/linkedIn.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/facebook.svg';

const Footer = (props) => {
  const dispatch = useDispatch();

  const onClickRequestDemo = () => {
    dispatch(setIsShowContactUs(true));
  }

  return (
    <Container fluid className="footerContainer">
      <Container>
        <Row className='footerContentSection'>
          <Col className='brandColumn'>
            <Image src={IRES_LOGO} className='brandLogo' />
          </Col>
          <Col className='socialIcons'>
            <a href="https://www.youtube.com/channel/UCQA05ywFRYyNb7gNPZpXIqg" target='_blank' rel="noopener noreferrer" className='socialIcon'>
              <YoutubeIcon />
            </a>
            <a href="https://www.instagram.com/ires_ai/" target='_blank' rel="noopener noreferrer" className='socialIcon'>
              <InstagramIcon />
            </a>
            <a href="https://www.linkedin.com/company/103485875/admin/feed/posts/" target='_blank' rel="noopener noreferrer" className='socialIcon'>
              <LinkedInIcon />
            </a>
            <a href="https://www.facebook.com/profile.php?id=61558581490835" target='_blank' rel="noopener noreferrer" className='socialIcon'>
              <FacebookIcon />
            </a>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className='copyrightContainer' lg={12}>
            <p>© Copyright 2024, All Rights Reserved</p>
            <div className='footerLinks'>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-conditions">Terms & Conditions</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Footer;

import React, { Component, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './SidebarStyles.css';
import { House, PersonCircle, Telephone, BoxArrowRight, HouseGearFill } from "react-bootstrap-icons";

function Sidebar({ color, image, routes, setIsSidebarExtended, show, setShow, onNavItemClick }) {

  const navigate = useNavigate();

  const onSidebarToggle = (toggle) => {
    console.log("Toggle value is ", toggle);
    setIsSidebarExtended(toggle);
  }

  return (
    <SideNav
      onSelect={(selected) => {
          // Add your code here
          console.log(selected, show);
          switch(selected){
            case 'logout':
            setShow(!show)
          }
      }}
      className="sideNavContainer"
      onToggle={(toggle)=>onSidebarToggle(toggle)}
    >
      <SideNav.Toggle />
      <SideNav.Nav defaultSelected="home">
        <NavItem eventKey="home" onClick={()=>onNavItemClick("home")}>
          <NavIcon>
            <HouseGearFill />
          </NavIcon>
          <NavText>
            Home
          </NavText>
        </NavItem>
        <NavItem eventKey="dashboard" onClick={()=>{navigate('/admin/dashboard')}}>
          <NavIcon>
            <House />
          </NavIcon>
          <NavText>
              Dashboard
          </NavText>
        </NavItem>
        <NavItem eventKey="profile">
            <NavIcon>
                <PersonCircle />
            </NavIcon>
            <NavText>
               Profile
            </NavText>
        </NavItem>
        <NavItem eventKey="callLogs">
            <NavIcon>
                <Telephone />
            </NavIcon>
            <NavText>
                Call logs
            </NavText>
        </NavItem>
        <NavItem eventKey="logout">
            <NavIcon>
                <BoxArrowRight />
            </NavIcon>
            <NavText>
              Logout
            </NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
}

export default Sidebar;

// src/components/ContactUsModal/ContactUsModal.jsx

import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBModal,
  MDBTextArea
} from 'mdb-react-ui-kit';
import './ContactUsModalStyles.css'
import { setIsShowContactUs } from "../../app/slices/commonSlice";
import { useDispatch, useSelector } from "react-redux";
import { db } from '../../firebaseConfig';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

const ContactUsModal = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const { isShowContactUs } = useSelector(state => state.commonSliceState);
  const dispatch = useDispatch();

  const closeContactUsModal = () => {
    dispatch(setIsShowContactUs(false));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Add a new document to the "contact" collection
      await addDoc(collection(db, 'contact'), {
        name: name,
        email: email,
        message: message,
        timestamp: serverTimestamp(),
      });

      alert('Message sent!');
      setName('');
      setEmail('');
      setMessage('');
      closeContactUsModal();
    } catch (error) {
      console.error('Error writing document: ', error);
      alert('Error sending message, please try again.');
    }
  };

  return (
    <MDBModal open={isShowContactUs} onClose={() => closeContactUsModal()} tabIndex='-1'>
      <MDBContainer fluid>
        <MDBRow className='d-flex justify-content-center align-items-center h-100'>
          <MDBCol col='12'>
            <MDBCard className='bg-dark text-white my-5 mx-auto' style={{ borderRadius: '1rem', maxWidth: '400px' }}>
              <MDBCardBody className='p-5 d-flex flex-column mx-auto w-100'>
                <MDBBtn style={{ textAlign: 'right', position: 'absolute', right: 20, top: 20, backgroundColor: '#fff' }} className='btn-close' color='none' onClick={() => closeContactUsModal()}></MDBBtn>
                <h2 className="mb-5 contactUsHeading">Contact Us</h2>
                <form onSubmit={handleSubmit}>
                  <MDBInput
                    value={name}
                    wrapperClass='mb-4 w-100'
                    labelClass='text-white'
                    label='Name'
                    id='formControlLg'
                    type='text'
                    size="lg"
                    onChange={(event) => setName(event.target.value)}
                    required
                  />
                  <MDBInput
                    value={email}
                    wrapperClass='mb-4 w-100'
                    labelClass='text-white'
                    label='Email address'
                    id='formControlLg'
                    type='email'
                    size="lg"
                    onChange={(event) => setEmail(event.target.value)}
                    required
                  />
                  <MDBTextArea
                    value={message}
                    labelClass='messageInputLabel'
                    label="Message"
                    id="messageTextArea"
                    rows="4"
                    className="messageInput"
                    onChange={(event) => setMessage(event.target.value)}
                    required
                  />
                  <MDBBtn color="primary" className="sendButton" type="submit">Send</MDBBtn>
                </form>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBModal>
  )
}

export default ContactUsModal;

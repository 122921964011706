// HeadsetIcon.js
import React from 'react';

const TimerIcon = () => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="48" height="48" rx="24" fill="#1D54B5" fill-opacity="0.1" />
        <path d="M26.5 31.5L28.5 33.5L33 29M33.9851 25.0499C33.995 24.8678 34 24.6845 34 24.5C34 18.9772 29.5228 14.5 24 14.5C18.4772 14.5 14 18.9772 14 24.5C14 29.9354 18.3365 34.358 23.7385 34.4966M24 18.5V24.5L27.7384 26.3692" stroke="#1D54B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>


);

export default TimerIcon;
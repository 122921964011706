import React, { useState } from "react";
import {
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBInput,
    MDBIcon,
    MDBModal,
    MDBTabs,
    MDBTabsItem,
    MDBTabsLink,
    MDBTabsContent,
    MDBTabsPane,
    MDBBadge
  }
  from 'mdb-react-ui-kit';
import './LoginModalStyles.css'

const LoginModal = ({ isShowLogin, setIsShowLogin, doLogin, username, password, setUserName, setPassword, isError }) => {
    console.log("I am in login modal ", isShowLogin);
    const [userRole, setUserRole] = useState('admin');
    
    const handleUserRole = (value) => {
        if (value === userRole) {
            return;
        }
        setUserRole(value);
    };

    return (<MDBModal open={isShowLogin} onClose={() => setIsShowLogin(false)} tabIndex='-1'>
        <MDBContainer fluid>
            <MDBRow className='d-flex justify-content-center align-items-center h-100'>
                <MDBCol col='12'>
                <MDBCard className='bg-dark text-white my-5 mx-auto' style={{borderRadius: '1rem', maxWidth: '400px'}}>
                    <MDBCardBody className='p-5 d-flex flex-column align-items-center mx-auto w-100'>
                    <MDBBtn style={{textAlign: 'right', position: 'absolute', right: 20, top: 20, backgroundColor: '#fff'}} className='btn-close' color='none' onClick={() => setIsShowLogin(false)}></MDBBtn>
                    <MDBTabs pills className='mb-3'>
                        <MDBTabsItem>
                            <MDBTabsLink className='mb-69' onClick={() => handleUserRole('admin')} active={userRole === 'admin'}>
                                Admin Login
                            </MDBTabsLink>
                        </MDBTabsItem>
                        <MDBTabsItem>
                            <MDBTabsLink onClick={() => handleUserRole('user')} active={userRole === 'user'}>
                                User Login
                            </MDBTabsLink>
                        </MDBTabsItem>
                    </MDBTabs>
                    {/* <h2 className="fw-bold mb-2 text-uppercase">Login</h2> */}
                    <MDBTabsContent>
                        <MDBTabsPane open={userRole === 'admin'}>Admin Login</MDBTabsPane>
                        <MDBTabsPane open={userRole === 'user'}>User Login</MDBTabsPane>
                    </MDBTabsContent>
                    <p className="text-white-50 mb-5">Please enter your login and password!</p>

                    <MDBInput value={username} wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Email address' id='formControlLg' type='email' size="lg" onChange={(event)=>setUserName(event.target.value)}/>
                    <MDBInput value={password} wrapperClass='mb-4 mx-5 w-100' labelClass='text-white' label='Password' id='formControlLg' type='password' size="lg" onChange={(event)=>setPassword(event.target.value)}/>
                                       


                    {/* <p className="small mb-3 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p> */}
                    <MDBBtn outline className='mx-2 px-5' color='white' size='lg' onClick={()=>doLogin(userRole)}>
                        Login
                    </MDBBtn>

                    {userRole!=='admin' && <div className="socialSignIn">
                        <p>or</p>
                        <p>Sign In with:-</p>
                        <MDBBtn outline className='mx-2 px-5' color='white' size='lg' onClick={()=>{}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-google" viewBox="0 0 16 16">
                                <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                            </svg>
                            <span className="googleText">Google</span>
                        </MDBBtn> 
                    </div>}

                    {/* <div className='d-flex flex-row mt-3 mb-5'>
                        <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                        <MDBIcon fab icon='facebook-f' size="lg"/>
                        </MDBBtn>

                        <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                        <MDBIcon fab icon='twitter' size="lg"/>
                        </MDBBtn>

                        <MDBBtn tag='a' color='none' className='m-3' style={{ color: 'white' }}>
                        <MDBIcon fab icon='google' size="lg"/>
                        </MDBBtn>
                    </div> */}

                    {/* <div>
                        <p className="mb-0">Don't have an account? <a href="#!" class="text-white-50 fw-bold">Sign Up</a></p>

                    </div> */}
                    {isError.isShowError && <MDBBadge color='warning' light style={{marginTop: 20, marginBottom: 20}}>{isError.message}</MDBBadge>}
                    </MDBCardBody>
                </MDBCard>

                </MDBCol>
            </MDBRow>
        </MDBContainer></MDBModal>)
}

export default LoginModal
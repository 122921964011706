// HeadsetIcon.js
import React from 'react';

const HeadsetIcon = () => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="48" height="48" rx="24" fill="#1D54B5" fillOpacity="0.1" />
        <path d="M33 30.5V24.5C33 19.5294 28.9706 15.5 24 15.5C19.0294 15.5 15 19.5294 15 24.5V30.5M17.5 33.5C16.1193 33.5 15 32.3807 15 31V29C15 27.6193 16.1193 26.5 17.5 26.5C18.8807 26.5 20 27.6193 20 29V31C20 32.3807 18.8807 33.5 17.5 33.5ZM30.5 33.5C29.1193 33.5 28 32.3807 28 31V29C28 27.6193 29.1193 26.5 30.5 26.5C31.8807 26.5 33 27.6193 33 29V31C33 32.3807 31.8807 33.5 30.5 33.5Z" stroke="#1D54B5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export default HeadsetIcon;
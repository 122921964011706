import { createSlice } from '@reduxjs/toolkit';
import { servicesTabs, headerPageContent } from '../dataUtils';

const initialState = {
  bannerSection: {
    heading: headerPageContent.banner.heading,
    subHeading: headerPageContent.banner.subHeading,
    button: {
        buttonName: headerPageContent.banner.buttonName
    }
  },
  servicesSection: {
    servicesTabsData: servicesTabs
  }
}

export const homeContentSlice = createSlice({
  name: 'homeContent',
  initialState,
  reducers: {
    setBannerSection: (state, action) => {
        const { bannerSection } = {...state};
        const button= bannerSection.button;
        bannerSection.heading = action.payload.heading;
        bannerSection.subHeading = action.payload.subHeading;
        button.buttonName = action.payload?.button?.buttonName;
    },
    setServicesTabsData: (state, action) => {
      const { servicesSection } = {...state};
      servicesSection.servicesTabsData = action.payload      
    },
  },
})

// Action creators are generated for each case reducer function
export const { setBannerSection, setServicesTabsData } = homeContentSlice.actions

export default homeContentSlice.reducer
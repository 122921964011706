import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
// import AboutPage from './pages/AboutPage/AboutPage';
// import NotFoundPage from './pages/NotFoundPage/NotFoundPage';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import AboutUs from './pages/AboutUs/AboutUs';
import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import { useSelector } from 'react-redux';
import ContactUsModal from './components/ContactUsModal/ContactUsModal';

function App() {

  const { isShowContactUs } = useSelector(state=>state.commonSliceState);
  console.log(isShowContactUs);

  useEffect(()=>{

  },[])
  
  const ShowFooter = () => {
    const location = useLocation();
    if (location.pathname !== "/admin" && location.pathname !== "/admin/dashboard") return <Footer />;
    else return <></>;
  };

  const ShowHeader = () => {
    const location = useLocation();
    if (location.pathname !== "/admin" && location.pathname !== "/admin/dashboard") return <Header />;
    else return <></>;
  };

  return (
    <Router>
      <div className="App">
        <ShowHeader />
          <Routes>
            {/* <Route path="/" element={<HomePage />} />
            <Route path='/about-us' element={<AboutUs />} /> 
            <Route path="/admin" element={<AdminDashboard />} /> */}
            <Route path='/' element={<HomePage />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='admin' element={<AdminDashboard />}>
              <Route path='dashboard' element={<div>Admin Dashboard</div>} />
            </Route>
            <Route path='/user' element={<div>User Dashboard</div>}>
            </Route>
          </Routes>
        <ShowFooter />
        {isShowContactUs && <div>
              <ContactUsModal />
        </div>}
      </div>
    </Router>
  );
}

export default App;

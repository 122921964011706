// HeadsetIcon.js
import React from 'react';

const LinkIcon = () => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 24.5C0 11.2452 10.7452 0.5 24 0.5C37.2548 0.5 48 11.2452 48 24.5C48 37.7548 37.2548 48.5 24 48.5C10.7452 48.5 0 37.7548 0 24.5Z" fill="#1D54B5" fill-opacity="0.1" />
        <path d="M23.5 17.5H23.9344C26.9816 17.5 28.5053 17.5 29.0836 18.0473C29.5836 18.5204 29.8051 19.2173 29.6702 19.8922C29.514 20.673 28.2701 21.5529 25.7823 23.3125L21.7177 26.1875C19.2299 27.9471 17.986 28.827 17.8298 29.6078C17.6949 30.2827 17.9164 30.9796 18.4164 31.4527C18.9947 32 20.5184 32 23.5656 32H24.5M20 17.5C20 19.1569 18.6569 20.5 17 20.5C15.3431 20.5 14 19.1569 14 17.5C14 15.8431 15.3431 14.5 17 14.5C18.6569 14.5 20 15.8431 20 17.5ZM34 31.5C34 33.1569 32.6569 34.5 31 34.5C29.3431 34.5 28 33.1569 28 31.5C28 29.8431 29.3431 28.5 31 28.5C32.6569 28.5 34 29.8431 34 31.5Z" stroke="#1D54B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

);

export default LinkIcon;
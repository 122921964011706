// HeadsetIcon.js
import React from 'react';

const LinkIcon = () => (
    <svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 24.5C0 11.2452 10.7452 0.5 24 0.5C37.2548 0.5 48 11.2452 48 24.5C48 37.7548 37.2548 48.5 24 48.5C10.7452 48.5 0 37.7548 0 24.5Z" fill="#1D54B5" fill-opacity="0.1" />
        <path d="M23 15.5H19.8C18.1198 15.5 17.2798 15.5 16.638 15.827C16.0735 16.1146 15.6146 16.5735 15.327 17.138C15 17.7798 15 18.6198 15 20.3V28.7C15 30.3802 15 31.2202 15.327 31.862C15.6146 32.4265 16.0735 32.8854 16.638 33.173C17.2798 33.5 18.1198 33.5 19.8 33.5H28.2C29.8802 33.5 30.7202 33.5 31.362 33.173C31.9265 32.8854 32.3854 32.4265 32.673 31.862C33 31.2202 33 30.3802 33 28.7V25.5M24 20.5H28V24.5M27.5 16V14.5M31.4393 17.0607L32.5 16M32.5103 21H34.0103M15 25.8471C15.6519 25.9478 16.3199 26 17 26C21.3864 26 25.2653 23.8276 27.6197 20.5" stroke="#1D54B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>



);

export default LinkIcon;
import React, { Component, useEffect, useState } from "react";
import { useLocation, Route, useNavigate } from "react-router-dom";

// import Footer from "components/Footer/Footer";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Container, Grid, Row } from "react-bootstrap";
import './AdminDashboardStyles.css'
import Alert from "../../components/Alert/Alert";
import EditHome from '../../components/EditHome/EditHome';

function AdminDashboard() {

  const [isSidebarExtended, setIsSidebarExtended] = useState(false);
  const [show, setShow] = useState(false);
  const [mainContent, setMainContent] = useState(<EditHome />);

  const navigate = useNavigate();

  useEffect(()=>{
  },[isSidebarExtended])

  const handleAction = (actionType) => {
    console.log(actionType);
    if(actionType==='primary'){
        navigate('/');
    }else{
        setShow(false);
    }
  }
  
  const onNavItemClick = (navItem) => {
    console.log("Nav item is ", navItem);
    switch(navItem){
      case "home":
      setMainContent(<EditHome />);
      return; 
    }
  }

  return <Container fluid className="dashboardContainer">
    <Sidebar 
      show={show} 
      setShow={(show)=>setShow(show)} 
      isSidebarExtended={isSidebarExtended} 
      setIsSidebarExtended={(toggle)=>setIsSidebarExtended(toggle)}
      onNavItemClick={(navItem)=>onNavItemClick(navItem)}
    />
    <Row className="adminContainer">
        {mainContent}
    </Row>
    <Alert show={show} primaryActionBtn="Yes" secondaryActionBtn="No" handleAction={handleAction}/>
  </Container>
}

export default AdminDashboard;

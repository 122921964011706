import React from 'react';
import './DynamicSection.css';
import { Button } from 'react-bootstrap';

const DynamicSection = ({
    heading,
    points,
    button,
    ButtonIcon,
    onClickCTA,
    image,
    layout,
    backgroundColor
}) => {
    const isLeftLayout = layout === 'left';

    return (
        <div className={`dynamic-section ${backgroundColor}`}>
            <div className={`content ${isLeftLayout ? 'left' : 'right'}`}>
                <div className={`image-container ${isLeftLayout ? 'left-image' : 'right-image'}`}>
                    <img src={image} alt="Section Visual" className="section-image" />
                    {/* <Icon /> */}
                </div>
                <div className="text-content">
                    <h2 className="headingSection">{heading}</h2>
                    <ul className="points-list">
                        {points.map((point, index) => (
                            <li key={index} className="point-item">
                                <div className='pointIcon-container'>
                                    <point.icon className="point-icon" />
                                </div>
                                <span style={{ marginLeft: '5px', textAlign: 'left' }}>{point.text}</span>
                            </li>
                        ))}
                    </ul>
                    <Button className='requestDemoBtn' onClick={onClickCTA}>
                        <span className='requestDemoBtnText'>{button?.text}</span>
                        <ButtonIcon className="ctaButtonIcon" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default DynamicSection;

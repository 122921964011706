import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Image, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import './HeaderStyles.css';
import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import LoginModal from '../LoginModal/LoginModal';
import IRES_LOGO from '../../assets/images/iRES_transparent.png'
import { useDispatch } from 'react-redux';
import { setIsShowContactUs } from '../../app/slices/commonSlice';
import { Link as ScrollLink } from 'react-scroll';
import '../../Variables.css';

const mql = window.matchMedia('(max-width: 992px)');

let mobileView = mql.matches;

const HeaderMobileView = ({ onClickRequestDemo, setIsShowLogin, isShowLogin }) => {
    const navigateToLogin = () => {
        window.location.href = 'https://buildcamp-39039.bubbleapps.io/login';
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant='dark'>
            <Navbar.Brand href="/" bsPrefix="navBarBrand">
                <Image src={IRES_LOGO} className='brandLogo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link onClick={() => onClickRequestDemo()}>
                        <span className='link'>Contact Us</span>
                    </Nav.Link>
                    <Button bsPrefix="loginButton" onClick={navigateToLogin}>
                        <span className='link loginLink'>Login</span>
                    </Button>
                    <Button bsPrefix='requestDemoBtn' onClick={() => onClickRequestDemo()}>
                        <span className='requestDemoBtnText'>Request Demo</span>
                    </Button>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

const Header = () => {
    const [isNavdropdownOpen, setIsNavdropdownOpen] = useState(false);
    const [isShowLogin, setIsShowLogin] = useState(false);
    const [isError, setIsError] = useState({
        isShowError: false,
        message: '',
        errorType: ''
    });
    const [username, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {}, [isShowLogin])

    const onResourcesClick = () => {
        setIsNavdropdownOpen(!isNavdropdownOpen);
    }

    const showLogin = (isShowLogin) => {
        setIsShowLogin(!isShowLogin);
    }

    const doLogin = (userRole) => {
        console.log("user role is ", userRole);
        if (userRole === 'admin') {
            if (username === 'admin@ires.com' && password === 'admin') {
                if (isError.isShowError) {
                    setIsError({
                        isShowError: false,
                        message: '',
                        errorType: ''
                    })
                }
                navigate('/admin');
            } else {
                if (username === '' && password === '') {
                    setIsError({
                        isShowError: true,
                        message: 'Username or password must be filled',
                        errorType: 'empty'
                    })
                } else {
                    setIsError({
                        isShowError: true,
                        message: 'Sorry! Wrong Credentials, please try again.',
                        errorType: 'wrongCredentials'
                    })
                }
            }
        } else {
            setIsShowLogin(false)
            navigate('/user');
        }
    }

    const onClickRequestDemo = () => {
        dispatch(setIsShowContactUs(true));
    }

    const navigateToLogin = () => {
        window.location.href = 'https://buildcamp-39039.bubbleapps.io/login';
    }

    if (mobileView) {
        return <>
            <HeaderMobileView onClickRequestDemo={onClickRequestDemo}
                isShowLogin={isShowLogin}
                setIsShowLogin={(isShowLogin) => {
                    console.log("isShowLogin in HeaderMobileView ", isShowLogin);
                    setIsShowLogin(isShowLogin)
                }}
            />
            {isShowLogin && <div className='loginModalContainer'>
                <LoginModal
                    username={username}
                    password={password}
                    isShowLogin={isShowLogin}
                    setIsShowLogin={(isShowLogin) => setIsShowLogin(isShowLogin)}
                    doLogin={(userRole) => doLogin(userRole)}
                    setUserName={(value) => setUserName(value)}
                    setPassword={(value) => setPassword(value)}
                    isError={isError}
                />
            </div>}
        </>
    } else {
        return (
            <>
                <Container fluid className='headerContainerNavBar'>
                    <Row className='headerRow'>
                        <div className='logoSection'>
                            <Link className='logoTitleContainer' to="/">
                                <Image src={IRES_LOGO} className='brandLogo' />
                            </Link>
                        </div>
                        <div className='links'>
                            <Link className='linkContainer'>
                                <ScrollLink
                                    to="productsSection"
                                    smooth={true}
                                    duration={500}
                                    offset={-300}
                                    className='linkContainer'
                                >
                                    <span className='link'>Products</span>
                                </ScrollLink>
                            </Link>
                            <Link className='linkContainer' onClick={() => onClickRequestDemo()}>
                                <span className='link'>Contact Us</span>
                            </Link>
                            <Button className='headerLoginButton' onClick={navigateToLogin}>
                                <span className='headerLoginButtonText'>Login</span>
                            </Button>
                            <Button className='requestDemoBtn' onClick={() => onClickRequestDemo()}>
                                <span className='requestDemoBtnText'>Request Demo</span>
                            </Button>
                        </div>
                    </Row>
                </Container>
                {isShowLogin && <div className='loginModalContainer'>
                    <LoginModal
                        username={username}
                        password={password}
                        isShowLogin={isShowLogin}
                        setIsShowLogin={(isShowLogin) => setIsShowLogin(isShowLogin)}
                        doLogin={(userRole) => doLogin(userRole)}
                        setUserName={(value) => setUserName(value)}
                        setPassword={(value) => setPassword(value)}
                        isError={isError}
                    />
                </div>
                }
            </>
        );
    }
}

export default Header;

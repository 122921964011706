import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Alert({show,  primaryActionBtn, secondaryActionBtn, handleAction}) {

  return (
    <>
      <Modal show={show} onHide={()=>handleAction('secondary')}>
        <Modal.Header closeButton>
          <Modal.Title>Do you really want to logout?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>handleAction('secondary')}>
            {secondaryActionBtn}
          </Button>
          <Button variant="primary" onClick={()=>handleAction('primary')}>
            {primaryActionBtn}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Alert;
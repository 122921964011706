// src/firebaseConfig.js

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";




const firebaseConfig = {
    apiKey: "AIzaSyBVOyXQ8g5_t9DQ4CNf1K1rwHwlGCG2W9M",
    authDomain: "ires-9e4a4.firebaseapp.com",
    databaseURL: "https://ires-9e4a4-default-rtdb.firebaseio.com",
    projectId: "ires-9e4a4",
    storageBucket: "ires-9e4a4.appspot.com",
    messagingSenderId: "1066412265912",
    appId: "1:1066412265912:web:fad6f64eccecfafced7f4d"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };






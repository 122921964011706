import React from 'react';
import './PricingCard.css';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

import { useDispatch } from 'react-redux';
import { setIsShowContactUs } from '../../app/slices/commonSlice';

const PricingCard = ({ title, features, buttonText, highlighted }) => {
    const dispatch = useDispatch();

    const onClickRequestDemo = () => {
        dispatch(setIsShowContactUs(true));
  }
    return (
        <div className={`pricing-card ${highlighted ? 'highlighted' : ''}`}>
            <div className='pricing-card-container'>
                <h3 className="pricing-card-title">{title}</h3>
                <ul className="features-list">
                    {features.map((feature, index) => (
                        <li key={index} className="feature-item">
                            <span className='check-container'>
                                <DoneOutlinedIcon className='check-icon' fontSize='80'/>
                            </span>
                            <div style={{textAlign: 'justify'}}>
                                {feature}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <button className="contact-button" onClick={onClickRequestDemo}>{buttonText}</button>
        </div>
    );
};

export default PricingCard;
